import { useStaticQuery, graphql } from "gatsby"

export const useAsmetPhotos = () => {
  const data = useStaticQuery(
    graphql`
      query PhotosQuery {
        allStrapiMediaFoto(sort: { order: DESC, fields: fecha }) {
          edges {
            node {
              strapiId
              slug
              titulo
              meta {
                og_titulo
                og_imagen {
                  publicURL
                }
                og_descripcion
              }
              fecha
              descripcion
              imagenes {
                formats {
                  thumbnail {
                    publicURL
                  }
                  small {
                    publicURL
                  }
                  medium {
                    publicURL
                  }
                  large {
                    publicURL
                  }
                }
                url
              }
            }
          }
        }
      }
    `
  )
  return data.allStrapiMediaFoto.edges
}
