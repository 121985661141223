import React from "react"
import { Link } from "gatsby"
import PropTypes from "prop-types"
import Moment from "react-moment"

// Components
import CustomLink from "../elements/custom-link"

// Include images for Demo purposes
const imgTemaDestacado = require("../../assets/images/media/tema_destacado-febrero.jpg")

const Highlights = ({ releases, videos, audios, photos, events }) => {
  return (
    <div id="asm-highlights" className="container my-5">
      <div className="row">
        <div className="col-sm-6 col-lg order-sm-1 order-lg-1 asm-releases d-sm-flex flex-column">
          <h3 className="text-600 py-3">Tema Destacado</h3>
          <div className="card flex-grow-1 rounded-0">
            <img
              className="card-img h-100 rounded-0"
              src={releases.imagen.publicURL}
              alt={releases.titulo}
            />
            <div className="card-img-overlay">
              <div className="card-content-overlay">
                <p className="card-title mb-0">{releases.titulo}</p>
              </div>
              <p className="card-date">{releases.subtitulo}</p>
              {releases.link ? (
                <CustomLink link={releases.link} stretchedLink={true} />
              ) : null}
            </div>
          </div>
        </div>

        <div className="col-sm-12 col-lg order-sm-3 order-lg-2 asm-media d-sm-flex flex-column">
          <ul className="nav nav-tabs nav-justified border-0" id="mediaTab">
            <li className="nav-item" role="presentation">
              <a
                className="nav-link py-0 px-1 border-0 active"
                id="videosTab"
                data-toggle="tab"
                href="#mediaVideos"
                role="tab"
                aria-controls="mediaVideos"
                aria-selected="true"
              >
                <h3 className="text-600 py-3">Videos</h3>
              </a>
            </li>
            <li className="nav-item" role="presentation">
              <a
                className="nav-link py-0 px-1 border-0"
                id="audiosTab"
                data-toggle="tab"
                href="#mediaAudios"
                role="tab"
                aria-controls="mediaAudios"
                aria-selected="false"
              >
                <h3 className="text-600 py-3">Audios</h3>
              </a>
            </li>
            <li className="nav-item" role="presentation">
              <a
                className="nav-link py-0 px-1 border-0"
                id="photosTab"
                data-toggle="tab"
                href="#mediaPhotos"
                role="tab"
                aria-controls="mediaPhotos"
                aria-selected="false"
              >
                <h3 className="text-600 py-3">Fotos</h3>
              </a>
            </li>
          </ul>
          <div className="tab-content flex-grow-1" id="mediaTabContent">
            <div
              className="tab-pane h-100 fade show active"
              id="mediaVideos"
              role="tabpanel"
              aria-labelledby="videosTab"
            >
              <CardCarousel
                carouselName="Videos"
                carouselPath="videos"
                cardsArray={videos}
              />
            </div>
            <div
              className="tab-pane h-100 fade"
              id="mediaAudios"
              role="tabpanel"
              aria-labelledby="audiosTab"
            >
              <CardCarousel
                carouselName="Audios"
                carouselPath="audios"
                cardsArray={audios}
              />
            </div>
            <div
              className="tab-pane h-100 fade"
              id="mediaPhotos"
              role="tabpanel"
              aria-labelledby="photosTab"
            >
              <CardCarousel
                carouselName="Fotos"
                carouselPath="fotos"
                cardsArray={photos}
              />
            </div>
          </div>
        </div>

        <div className="col-sm-6 col-lg order-sm-2 order-lg-2 asm-events d-sm-flex flex-column">
          <h3 className="text-600 py-3">
            Eventos
            <Link
              to={`/events`}
              className="text-blue-alpha text-600 float-right mr-3"
            >
              Más
            </Link>
          </h3>

          <div className="d-sm-flex flex-column flex-grow-1">
            {events.length === 0 ? (
              <h5 className="text-center">No hay eventos!</h5>
            ) : (
              <div className="card card-top border-0 mb-2">
                <img
                  className="card-img-top rounded"
                  src={
                    events[0].node.imagen_principal
                      ? events[0].node.imagen_principal.publicURL
                      : "http://placehold.it/365x365?text=Evento"
                  }
                  alt={events[0].node.nombre}
                />
                <div className="card-body p-2">
                  <small className="card-text d-flex align-items-center mb-1">
                    <i className="fa fa-calendar fa-lg text-blue-beta mr-2"></i>
                    <Moment format="LL">
                      {events[0].node.fecha}
                    </Moment>
                  </small>
                  <h5 className="card-title mb-1">{events[0].node.nombre}</h5>
                  <Link
                    to={`/events/${events[0].node.slug}`}
                    className="stretched-link"
                  >
                    {""}
                  </Link>
                </div>
              </div>
            )}
            {events[1] ? (
              <div className="card card-bottom border-0 flex-grow-1">
                <div className="row no-gutters h-100 position-relative">
                  <div className="col-md-5">
                    <img
                      className="card-img h-100"
                      src={
                        events[1].node.imagen_principal
                          ? events[1].node.imagen_principal.publicURL
                          : "http://placehold.it/365x365?text=Evento"
                      }
                      alt={events[1].node.nombre}
                    />
                  </div>
                  <div className="col-md-7 position-static">
                    <div className="card-body p-2">
                      <small className="card-text d-flex align-items-center mb-3">
                        <i className="fa fa-calendar fa-lg text-blue-beta mr-2"></i>
                        <Moment format="LL">
                          {events[1].node.fecha}
                        </Moment>
                      </small>
                      <h5 className="card-title mb-1">
                        {events[1].node.nombre}
                      </h5>
                    </div>
                    <Link
                      to={`/events/${events[1].node.slug}`}
                      className="stretched-link"
                    >
                      {""}
                    </Link>
                  </div>
                </div>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  )
}

const CardCarousel = ({ carouselName, cardsArray, carouselPath }) => {
  return cardsArray.length === 0 ? (
    <h5 className="text-center text-400">No content!</h5>
  ) : (
    <div
      id={"mediaCarousel" + carouselName}
      className="carousel slide h-100"
      data-ride="carousel"
    >
      <ol className="carousel-indicators">
        {cardsArray.slice(0, 3).map((item, index) => (
          <li
            data-target={"#mediaCarousel" + carouselName}
            data-slide-to={index}
            className={index === 0 ? "active" : ""}
            key={index}
          ></li>
        ))}
      </ol>
      <div className="carousel-inner h-100">
        {cardsArray.slice(0, 3).map((item, index) => (
          <div
            className={"carousel-item h-100" + (index === 0 ? " active" : "")}
            key={index}
          >
            <div className="card h-100 rounded-0 border-0">
              {item.node.link ? (
                <div className="embed-responsive embed-responsive-16by9">
                  <iframe
                    className="embed-responsive-item"
                    src={`https://www.youtube.com/embed/${item.node.link}?rel=0`}
                    title={`https://www.youtube.com/embed/${item.node.link}?rel=0`}
                    allowFullScreen
                  ></iframe>
                </div>
              ) : item.node.archivo ? (
                <audio
                  controls
                  className="w-100"
                  src={item.node.archivo.publicURL}
                >
                  <track default kind="captions" srcLang="en" />
                  Su navegador no soporta elementos de
                  <code>audio</code>.
                </audio>
              ) : item.node.imagenes ? (
                <img
                  src={item.node.imagenes[0].formats.small.publicURL}
                  className="card-img-top"
                  alt={item.node.titulo}
                />
              ) : null}

              <div className="card-body p-3">
                <h4 className="card-title text-600 mb-1">{item.node.titulo}</h4>
                <small className="card-text">
                  Publicado el&nbsp;
                  <Moment format="LL">
                    {item.node.fecha ? item.node.fecha : item.node.publicado_en}
                  </Moment>
                </small>
                <Link
                  to={`/${carouselPath}`}
                  className="btn btn-link text-400 p-0 mt-auto"
                  role="button"
                >
                  Ver Galería de {carouselName}
                </Link>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

Highlights.propTypes = {
  releases: PropTypes.shape({
    titulo: PropTypes.string.isRequired,
    subtitulo: PropTypes.string.isRequired,
    imagen: PropTypes.shape({
      publicURL: PropTypes.string.isRequired,
    }).isRequired,
    link: PropTypes.shape({
      tipo: PropTypes.string.isRequired,
      link_id: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,

  videos: PropTypes.arrayOf(
    PropTypes.shape({
      node: PropTypes.shape({
        titulo: PropTypes.string.isRequired,
        link: PropTypes.string.isRequired,
        publicado_en: PropTypes.string.isRequired,
      }),
    })
  ).isRequired,

  audios: PropTypes.arrayOf(
    PropTypes.shape({
      node: PropTypes.shape({
        titulo: PropTypes.string.isRequired,
        publicado_en: PropTypes.string.isRequired,
        archivo: PropTypes.shape({
          publicURL: PropTypes.string.isRequired,
        }).isRequired,
      }),
    })
  ).isRequired,

  photos: PropTypes.arrayOf(
    PropTypes.shape({
      node: PropTypes.shape({
        titulo: PropTypes.string.isRequired,
        fecha: PropTypes.string.isRequired,
        imagenes: PropTypes.arrayOf(
          PropTypes.shape({
            formats: PropTypes.shape({
              small: PropTypes.shape({
                publicURL: PropTypes.string.isRequired,
              }).isRequired,
            }).isRequired,
          })
        ).isRequired,
      }),
    })
  ).isRequired,

  events: PropTypes.arrayOf(
    PropTypes.shape({
      node: PropTypes.shape({
        strapiId: PropTypes.string.isRequired,
        nombre: PropTypes.string.isRequired,
        slug: PropTypes.string.isRequired,
        fecha: PropTypes.string.isRequired,
        imagen_principal: PropTypes.shape({
          publicURL: PropTypes.string.isRequired,
        }).isRequired,
      }),
    })
  ).isRequired,
}

Highlights.defaultProps = {
  releases: {
    titulo: `Conocer tu Cuerpo Puede salvarte la vida`,
    subtitulo: `Febrero`,
    imagen: {
      publicURL: imgTemaDestacado,
    },
    link: {
      nombre: `Link`,
      tipo: `Pagina`,
      link_id: `tema-destacado-custom`,
    },
  },

  videos: [
    {
      node: {
        titulo: `Diabetes Gestacional`,
        link: `4r0IqcCBBVY`,
        publicado_en: `2020-12-17T17:00:00.000Z`,
      },
    },
  ],
  audios: [
    {
      node: {
        titulo: `Cambio de régimen`,
        publicado_en: `2020-12-17T17:00:00.000Z`,
        archivo: {
          publicURL: `/static/0c481e87f2774b1bd41a0a70d9b70d11/4d1eaa21992d880810d7f98e7ceb959d.mp3`,
        },
      },
    },
  ],
  photos: [
    {
      node: {
        titulo: `Jornada de vacunación`,
        fecha: `2021-01-12T17:00:00.000Z`,
        imagenes: [
          {
            formats: {
              small: {
                publicURL: `/static/87cea906df25913f9665494d7a868511/8e683a150fb1472c7434cde2390cce65.png`,
              },
            },
          },
        ],
      },
    },
  ],
  events: [
    {
      node: {
        strapiId: `0`,
        nombre: `Día mundíal de la Diabetes`,
        slug: `dia-mundial-diabetes`,
        fecha: `2020-12-17T17:00:00.000Z`,
        imagen_principal: {
          publicURL: `/static/5be98cb6c705afdfeea841fc55f7f129/79e857a07f66e8a4bf7d06866f2a6ae1.png`,
        },
      },
    },
    {
      node: {
        strapiId: `0`,
        nombre: `Día mundial de la Neumonía`,
        slug: `dia-mundial-neumonia`,
        fecha: `2020-12-17T17:00:00.000Z`,
        imagen_principal: {
          publicURL: `/static/5be98cb6c705afdfeea841fc55f7f129/79e857a07f66e8a4bf7d06866f2a6ae1.png`,
        },
      },
    },
  ],
}

export default Highlights
