import React from "react"
import { Link } from "gatsby"
import PropTypes from "prop-types"

// Components
import CustomLink from "../elements/custom-link"
import CarouselIcon from "../elements/carousel-icon"

const QuickLinks = props => {
  const { bannerSlides, enlacesRapidos } = props
  return (
    <div id="asm-quick-links" className="my-0 my-sm-5">
      <div className="container">
        <div className="row">
          <div className="col-md-6 col-lg-7 banner-content-left text-center px-0">
            <CarouselIcon slides={bannerSlides} carouselId={1}/>
          </div>
          <div className="col-md-6 col-lg-5 banner-content-right text-left py-3 pl-0 pl-sm-5">
            {/* Only if SM os greater */}
            <div className="d-none d-md-block">
              <h5 className="banner-heading mb-3 mb-xl-4">Enlaces Rápidos</h5>
              {renderQuickLinks(enlacesRapidos)}
            </div>
            {/* Only if XS */}
            <div className="accordion d-block d-md-none" id="quickLinksAccordion">
              <div className="card bg-gray-alpha rounded-0 border-0 shadow-none">
                <div className="card-header" id="quickLinksHeading">
                  <Link
                    to={`/`}
                    className="text-left px-0"
                    role="button"
                    data-toggle="collapse"
                    data-target="#quickLinksCollapseOne"
                    aria-expanded="false"
                    aria-controls="quickLinksCollapseOne"
                  >
                    <h5 className="banner-heading m-0">
                      Enlaces Rápidos
                      <i className="fa fa-angle-down float-right"></i>
                    </h5>
                  </Link>
                </div>
                <div
                  id="quickLinksCollapseOne"
                  className="collapse"
                  aria-labelledby="quickLinksHeading"
                  data-parent="#quickLinksAccordion"
                >
                  <div className="card-body">
                    {renderQuickLinks(enlacesRapidos)}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

function renderQuickLinks(enlacesRapidos) {
  return (
    <ul>
      {enlacesRapidos.length === 0 ? (
        <li>No content!</li>
      ) : (
        enlacesRapidos.map((item, index) => (
          <li key={index}>
            <CustomLink link={item}>
              {item.nombre}
              <i className="fa fa-angle-right text-white-alpha"></i>
            </CustomLink>
          </li>
        ))
      )}
    </ul>
  )
}
QuickLinks.propTypes = {
  bannerSlides: PropTypes.arrayOf(
    PropTypes.shape({
      titulo: PropTypes.string.isRequired,
      subtitulo: PropTypes.string,
      imagen: PropTypes.shape({
        publicURL: PropTypes.string.isRequired,
      }).isRequired,
      link: PropTypes.shape({
        nombre: PropTypes.string.isRequired,
        tipo: PropTypes.string.isRequired,
        link_id: PropTypes.string.isRequired,
      }),
    })
  ).isRequired,
  enlacesRapidos: PropTypes.arrayOf(
    PropTypes.shape({
      nombre: PropTypes.string.isRequired,
      tipo: PropTypes.string.isRequired,
      link_id: PropTypes.string.isRequired,
    })
  ).isRequired,
}

QuickLinks.defaultProps = {
  bannerSlides: [
    {
      titulo: `Movilidad y Servicios`,
      imagen: {
        publicURL: `/static/b966ed1730e6e4b91017473db6c572c4/a0714058997f93785db63d9256cca7b2.png`,
      },
      link: {
        nombre: `Infórmate aquí`,
        tipo: `Pagina`,
        link_id: `5fe4770368cc14220204cae4`,
      },
    },
    {
      titulo: `Teeçx Ãjteçxa Akwa`,
      subtitulo: `Ptasxnxi.`,
      imagen: {
        publicURL: `/static/64a1c492a90e23e4aff745cc9debb823/4fe46ddf8269466efb3d53affd3f7e4c.png`,
      },
    },
  ],
  enlacesRapidos: [
    {
      nombre: `Copagos y Cuotas Moderadoras`,
      tipo: `Pagina`,
      link_id: `/`,
    },
    {
      nombre: `Cartilla Unificada del Usuario y del Paciente`,
      tipo: `Pagina`,
      link_id: `/`,
    },
  ],
}

export default QuickLinks
