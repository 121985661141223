import { useStaticQuery, graphql } from "gatsby"

export const useAsmetEvents = () => {
  const data = useStaticQuery(
    graphql`
      query EventsQuery {
        allStrapiMediaEvento(sort: { order: DESC, fields: fecha }) {
          edges {
            node {
              strapiId
              slug
              nombre
              meta {
                og_titulo
                og_imagen {
                  publicURL
                }
                og_descripcion
              }
              fecha
              imagen_principal {
                publicURL
              }
              descripcion

              contenido {
                id
                tipo_componente

                # Component Texto

                contenido

                # Component Documento

                titulo
                fecha_publicacion
                es_descargable
                descripcion
                archivo {
                  publicURL
                  extension
                }

                # Component Banner

                slides {
                  tipo_componente
                  titulo
                  subtitulo
                  imagen {
                    publicURL
                  }
                  link {
                    nombre
                    tipo
                    link_id
                    icono {
                      publicURL
                    }
                  }
                }

                # Component Card

                imagen {
                  publicURL
                }
                link {
                  nombre
                  tipo
                  link_id
                  icono {
                    publicURL
                  }
                }

                # Component Grupo Cards

                cards {
                  tipo_componente
                  titulo
                  descripcion
                  imagen {
                    publicURL
                  }
                  link {
                    nombre
                    tipo
                    link_id
                    icono {
                      publicURL
                    }
                  }
                }
              }
            }
          }
        }
      }
    `
  )
  return data.allStrapiMediaEvento.edges
}
