import React from "react"
import { Link } from "gatsby"
import PropTypes from "prop-types"
import Moment from "react-moment"
import { FacebookProvider, Page } from "react-facebook"

const News = ({ notices }) => {
  return (
    <div id="asm-news" className="container my-5 px-0 px-sm-3">
      <h3 className="text-600 py-2 ml-3 ml-sm-0">
        Últimas noticias
        <Link
          to={`/noticias`}
          className="text-gray-alpha text-600 float-right mr-3"
        >
          Más
          {/* <i className="fa fa-long-arrow-right ml-1"></i> */}
        </Link>
      </h3>
      <div className="row mx-0 mx-sm-n3">
        {notices.length === 0 ? (
          <div className="col">
            <h5 className="text-left">No hay noticias!</h5>
          </div>
        ) : (
          notices.slice(0, 3).map((item, index) => (
            <div className="col-sm-6 col-lg mb-4 mb-lg-0 px-0 px-sm-3" key={index}>
              <div className="card h-100">
                <div className="card-img-container">
                  <img
                    className="card-img-top h-100"
                    src={
                      item.node.imagen_principal
                        ? item.node.imagen_principal.publicURL
                        : "http://placehold.it/365x365?text=Noticia"
                    }
                    alt={item.node.titulo}
                  />
                </div>
                <div className="card-body">
                  <p className="card-text mb-1">
                    <Moment format="LL">{item.node.fecha}</Moment>
                  </p>
                  <h6 className="card-title mb-0 mb-sm-2">
                    {item.node.titulo}
                  </h6>
                  <Link
                    to={`/noticias/${item.node.slug}`}
                    className="btn btn-link p-0 stretched-link"
                    role="button"
                  >
                    Leer más
                    <i className="fa fa-angle-double-right ml-1"></i>
                  </Link>
                </div>
              </div>
            </div>
          ))
        )}

        <div className="col-sm-6 col-lg mb-4 mb-lg-0 text-center d-none d-sm-block">
          <div className="social-feed">
            <FacebookProvider appId="220141319793057" wait={true}>
              <Page
                href="https://www.facebook.com/asmetsaludeps/"
                tabs="timeline"
                height="400px"
                smallHeader={true}
                adaptContainerWidth={true}
              />
            </FacebookProvider>
          </div>
        </div>
      </div>
    </div>
  )
}

News.propTypes = {
  notices: PropTypes.arrayOf(
    PropTypes.shape({
      node: PropTypes.shape({
        strapiId: PropTypes.string.isRequired,
        titulo: PropTypes.string.isRequired,
        slug: PropTypes.string.isRequired,
        fecha: PropTypes.string.isRequired,
        imagen_principal: PropTypes.shape({
          publicURL: PropTypes.string.isRequired,
        }).isRequired,
      }),
    })
  ).isRequired,
}

News.defaultProps = {
  notices: [
    {
      node: {
        strapiId: `0`,
        titulo: `Día mundíal de la Diabetes`,
        slug: `dia-mundial-diabetes`,
        fecha: `2020-12-17T17:00:00.000Z`,
        imagen_principal: {
          publicURL: `/static/5be98cb6c705afdfeea841fc55f7f129/79e857a07f66e8a4bf7d06866f2a6ae1.png`,
        },
      },
    },
    {
      node: {
        strapiId: `0`,
        titulo: `Día mundial de la Neumonía`,
        slug: `dia-mundial-neumonia`,
        fecha: `2020-12-17T17:00:00.000Z`,
        imagen_principal: {
          publicURL: `/static/5be98cb6c705afdfeea841fc55f7f129/79e857a07f66e8a4bf7d06866f2a6ae1.png`,
        },
      },
    },
  ],
}

export default News
