import React from "react"
import PropTypes from "prop-types"

// Components
import Carousel from "../elements/carousel"
import CustomLink from "../elements/custom-link"

const Banner = props => {
  const { bannerPromo, bannerSlides } = props

  return (
    <div id="asm-main-banner" className="mb-0 mb-sm-5">
      <div className="container">
        <div className="row">
          <div
            className="col-sm-6 col-lg-5 banner-content-left text-left px-4 pl-md-3 pr-md-5 pt-5 pt-md-0"
            {...(bannerPromo.imagen
              ? {
                  style: {
                    "--banner-content-left-bg": `url(${bannerPromo.imagen.publicURL})`,
                  },
                }
              : {})}
          >
            <p className="banner-heading mb-3">{bannerPromo.titulo}</p>
            <p className="banner-subheading mb-4">{bannerPromo.subtitulo}</p>
            <CustomLink
              link={bannerPromo.link}
              customClass="btn btn-asm-white-alpha text-red-alpha px-5 py-2"
              roleButton={true}
            >
              {bannerPromo.link.nombre}
            </CustomLink>
          </div>

          <div className="col-sm-6 col-lg-7 banner-content-right d-none d-sm-block px-0">
            <Carousel slides={bannerSlides} carouselId={0}/>
          </div>
        </div>
      </div>
    </div>
  )
}

Banner.propTypes = {
  bannerPromo: PropTypes.shape({
    titulo: PropTypes.string.isRequired,
    subtitulo: PropTypes.string,
    imagen: PropTypes.shape({
      publicURL: PropTypes.string.isRequired,
    }).isRequired,
    link: PropTypes.shape({
      nombre: PropTypes.string.isRequired,
      tipo: PropTypes.string.isRequired,
      link_id: PropTypes.string.isRequired,
    }),
  }).isRequired,

  bannerSlides: PropTypes.arrayOf(
    PropTypes.shape({
      titulo: PropTypes.string.isRequired,
      subtitulo: PropTypes.string,
      imagen: PropTypes.shape({
        publicURL: PropTypes.string.isRequired,
      }),
      link: PropTypes.shape({
        nombre: PropTypes.string.isRequired,
        tipo: PropTypes.string.isRequired,
        link_id: PropTypes.string.isRequired,
      }),
    })
  ).isRequired,
}

Banner.defaultProps = {
  // bannerLeftImage: `url(http://placehold.it/465x465?text=BannerLeftImage)`,
  // bannerRightImage: `url(http://placehold.it/800x465?text=BannerRightImage)`,
  bannerPromo: {
    titulo: `Micrositio COVID-19`,
    subtitulo: `Informáte sobre la situación actual del COVID-19 y las líneas y horarios de atención.`,
    imagen: {
      publicURL: `/static/4bec2cf398c31338fc9711fe72416008/7cb6512038f4cf237fda778358667b72.png`,
    },
    link: {
      nombre: `Conoce más`,
      tipo: `Pagina`,
      link_id: `5fe4770368cc14220204cae4`,
    },
  },
  bannerSlides: [
    {
      titulo: `Movilidad y Servicios`,
      imagen: {
        publicURL: `/static/b966ed1730e6e4b91017473db6c572c4/a0714058997f93785db63d9256cca7b2.png`,
      },
      link: {
        nombre: `Infórmate aquí`,
        tipo: `Pagina`,
        link_id: `5fe4770368cc14220204cae4`,
      },
    },
    {
      titulo: `Teeçx Ãjteçxa Akwa`,
      subtitulo: `Ptasxnxi.`,
      imagen: {
        publicURL: `/static/64a1c492a90e23e4aff745cc9debb823/4fe46ddf8269466efb3d53affd3f7e4c.png`,
      },
    },
  ],
}

export default Banner
