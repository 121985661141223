import React from "react"
import PropTypes from "prop-types"

// Components
import CustomLink from "../elements/custom-link"

const Clients = props => {
  const { institutions } = props

  return (
    <div id="asm-clients" className="container my-5">
      <div className="row justify-content-center">
        {institutions.length === 0 ? (
          <h5 className="text-center">No content!</h5>
        ) : (
          institutions.slice(0, 7).map((item, index) => (
            <div className="col-3 col-sm ds-flex-center my-2 my-sm-0" key={index}>
              <img
                className="logo-institution"
                src={item.icono.publicURL}
                alt={item.nombre}
              />
              <CustomLink link={item} stretchedLink={true} />
            </div>
          ))
        )}
      </div>
    </div>
  )
}

Clients.propTypes = {
  institutions: PropTypes.arrayOf(
    PropTypes.shape({
      nombre: PropTypes.string.isRequired,
      tipo: PropTypes.string.isRequired,
      link_id: PropTypes.string.isRequired,
      icono: PropTypes.shape({
        publicURL: PropTypes.string.isRequired,
      }).isRequired,
    })
  ).isRequired,
}

Clients.defaultProps = {
  institutions: [
    {
      nombre: `Ministerio de Salud`,
      tipo: `Externo`,
      link_id: `/`,
      icono: {
        publicURL: `/static/9f31962c4b48544ffa82b6d58d02e4d7/07e66a3cab2aeb4e34f3052d908c50fa.png`,
      },
    },
  ],
}

export default Clients
