import React from "react"

// Layout
import Layout from "../components/layout"
// Components
import Menu from "../components/home/menu"
import News from "../components/home/news"
import Banner from "../components/home/banner"
import Clients from "../components/home/clients"

import QuickLinks from "../components/home/quick-links"
import Highlights from "../components/home/highlights"

// Hooks
import { useAsmetHome } from "../hooks/use-asmet-home"
import { useAsmetVideos } from "../hooks/use-asmet-videos"
import { useAsmetAudios } from "../hooks/use-asmet-audios"
import { useAsmetEvents } from "../hooks/use-asmet-events"
import { useAsmetPhotos } from "../hooks/use-asmet-photos"
import { useAsmetNotices } from "../hooks/use-asmet-notices"

const IndexPage = () => {
  const asmetHome = useAsmetHome()
  const allAudios = useAsmetAudios()
  const allVideos = useAsmetVideos()
  const allEvents = useAsmetEvents()
  const allPhotos = useAsmetPhotos()
  const allNotices = useAsmetNotices()

  return (
    <Layout meta={asmetHome.meta}>
      <Banner
        bannerPromo={asmetHome.promocion_principal}
        bannerSlides={asmetHome.banner_principal.slides}
      />
      <Menu menuSecundario={asmetHome.menu_secundario} />
      <QuickLinks
        bannerSlides={asmetHome.banner_secundario.slides}
        enlacesRapidos={asmetHome.enlaces_rapidos}
      />
      <News notices={allNotices} />
      <Highlights
        videos={allVideos}
        audios={allAudios}
        photos={allPhotos}
        events={allEvents}
        releases={asmetHome.tema_destacado}
      />
      <Clients institutions={asmetHome.instituciones} />
    </Layout>
  )
}

export default IndexPage
