import React from "react"
import PropTypes from "prop-types"

// Components
import CustomLink from "./custom-link"

const CarouselIconSlides = props => {
  const { slides, carouselId } = props

  return (
    <div className="asm-carousel-slides">
      <div
        id={"carouselIconCaptions" + carouselId}
        className="carousel slide"
        data-ride="carousel"
      >
        <ol className="carousel-indicators">
          {slides.map((item, index) => {
            return (
              <li
                data-target={"#carouselIconCaptions" + carouselId}
                data-slide-to={index}
                className={index === 0 ? "active" : ""}
                key={index}
              ></li>
            )
          })}
        </ol>
        <div className="carousel-inner">
          {slides.map((item, index) => {
            return (
              <div
                className={"carousel-item" + (index === 0 ? " active" : "")}
                key={index}
              >
                <img
                  src={item.imagen?.publicURL}
                  className="d-block w-100"
                  alt={item.titulo}
                />
                {/* <img
                  src={bannerPromo.imagen.publicURL}
                  alt={bannerPromo.titulo}
                  // Display img only on XS
                  className="d-block d-sm-none mx-auto w-50"
                /> */}
                <div className="carousel-caption text-center text-sm-left px-5 px-sm-3 py-3 py-sm-4 pb-5">
                  {item.link && item.link.icono && (
                    <img
                      src={item.link.icono.publicURL}
                      alt={item.link.nombre}
                      className="mb-4"
                    />
                  )}
                  <p className="carousel-title mb-2 mb-sm-4">{item.titulo}</p>
                  {item.subtitulo && (
                    <p className="carousel-subtitle">{item.subtitulo}</p>
                  )}
                  {item.link && (
                    <CustomLink
                      link={item.link}
                      customClass="btn btn-asm-red-alpha text-gray-alpha rounded-pill px-5 py-2"
                      stretchedLink={true}
                      roleButton={true}
                    >
                      {item.link.nombre}
                      <i className="fa fa-long-arrow-right ml-3"></i>
                    </CustomLink>
                  )}
                </div>
              </div>
            )
          })}
        </div>
        <a
          className="carousel-control-prev"
          href={"#carouselIconCaptions" + carouselId}
          role="button"
          data-slide="prev"
        >
          <span
            className="carousel-control-prev-icon"
            aria-hidden="true"
          ></span>
          <span className="sr-only">Previous</span>
        </a>
        <a
          className="carousel-control-next"
          href={"#carouselIconCaptions" + carouselId}
          role="button"
          data-slide="next"
        >
          <span
            className="carousel-control-next-icon"
            aria-hidden="true"
          ></span>
          <span className="sr-only">Next</span>
        </a>
      </div>
    </div>
  )
}

CarouselIconSlides.propTypes = {
  carouselId: PropTypes.number,
  slides: PropTypes.arrayOf(
    PropTypes.shape({
      titulo: PropTypes.string.isRequired,
      subtitulo: PropTypes.string,
      imagen: PropTypes.shape({
        publicURL: PropTypes.string.isRequired,
      }).isRequired,
      link: PropTypes.shape({
        nombre: PropTypes.string.isRequired,
        tipo: PropTypes.string.isRequired,
        link_id: PropTypes.string.isRequired,
      }),
    })
  ).isRequired,
}

CarouselIconSlides.defaultProps = {
  carouselId: 0,
  slides: [
    {
      titulo: `Titulo Slide`,
      subtitulo: `Lorem, ipsum dolor sit amet consectetur adipisicing elit. Inventore magnam tempore nobis dolores illo recusandae saepe minima quas eaque corporis provident sint voluptates.`,
      imagen: {
        publicURL: `/static/64a1c492a90e23e4aff745cc9debb823/4fe46ddf8269466efb3d53affd3f7e4c.png`,
      },
      link: {
        nombre: `Link`,
        tipo: `Pagina`,
        link_id: `5fe4770368cc14220204cae4`,
      },
    },
    {
      titulo: `Titulo Slide`,
      subtitulo: `Lorem, ipsum dolor sit amet consectetur adipisicing elit. Inventore magnam tempore nobis dolores illo recusandae saepe minima quas eaque corporis provident sint voluptates.`,
      imagen: {
        publicURL: `/static/64a1c492a90e23e4aff745cc9debb823/4fe46ddf8269466efb3d53affd3f7e4c.png`,
      },
    },
  ],
}

export default CarouselIconSlides
